<footer class="site-footer" id="contact-section" style="background-color:#eff3fd">
    <div class="container"  style="padding-bottom: 40px;">
      <div class="row">
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-6">
              <h2 class="footer-heading mb-4" style="font-weight:700;">UDHD</h2>
           <p style="text-align:justify">The Urban Development department allocates resources to the State Governments through various Centrally Sponsored schemes, provides finances through national financial institutions and supports various external assistance programmes for housing and urban development in the country as a whole.
</p>
           </div>
            <div class="col-md-3 ml-auto quicklinks" style="margin-left: 139px;">
              <h2 class="footer-heading mb-4" style="font-weight:700;"> Quick Links</h2>
              <ul class="list-unstyled">
                <li><a href="#about-section" class="smoothscroll">Home</a></li>
                <li><a href="#about-section" class="smoothscroll">About Us</a></li>
                <li><a href="#services-section" class="smoothscroll">Our Services</a></li>
                <li><a href="#contact-section" class="smoothscroll">Contact Us</a></li>
              </ul>
            </div>
          
          </div>
        </div>
        <div class="col-md-3">
          <h2 class="footer-heading mb-4" style="font-weight:700;">Contact Us</h2>
           <ul class="list-unstyled" style="width: 400px;">
                <li><a href="#about-section" class="smoothscroll">Phone : 0114-0747447</a></li>
                <li><a href="#about-section" class="smoothscroll">Email: bihar.calllogs@gmail.com
</a></li>
                <li><a href="#services-section" class="smoothscroll">Address :New Market, G.P.O. Golomber,<br> New Market Station Rd,<br> Patna, Bihar 800001</a></li>
              </ul>
        </div>
      </div>
      
     
      </div>
       <div class="row text-center text-center1">
        <div class="col-md-12">
          <div class="border-top pt-4">
            <p class="copyright" style="color:#cacaca"><small>
              <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
              <img src="assets/images/UDD-Logo.png" class="img-fluid" width="60px"><br>
             © Copyright - UDHD 2021 |  Development By - <a href="#" target="_blank"  style="color: #ababab;
  text-decoration: none;
  font-weight: 800;">Logikoof Technologies </a>
              <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --></small></p>

            </div>
          </div>

        </div>
    </footer>

