<div id="overlayer"></div>
<div class="loader">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div class="site-wrap">
<app-header *ngIf="!router.url.includes('/servicedashboard') && !router.url.includes('/revenuedashboard')"></app-header>
<router-outlet></router-outlet>
<app-footer *ngIf="!router.url.includes('/servicedashboard') && !router.url.includes('/revenuedashboard')"></app-footer>
</div>