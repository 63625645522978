<div class="site-mobile-menu site-navbar-target">
    <div class="site-mobile-menu-header">
      <div class="site-mobile-menu-close mt-3">
        <span class="icon-close2 js-menu-toggle"></span>
      </div>
    </div>
    <div class="site-mobile-menu-body"></div>
  </div>
  <header class="site-navbar js-sticky-header site-navbar-target" role="banner">

    <div class="container">
      <div class="row align-items-center">
          <div class="col-6 col-xl-2">
          <div class="mb-0 site-logo"><a href="javascript:void(0)" class="mb-0">UDHD<span class="text-primary">.</span> </a></div>
        </div>

        <div class="col-12 col-md-10 d-none d-xl-block">
          <nav class="site-navigation position-relative text-right" role="navigation">
              <ul class="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
              <li><a href="#home-section" class="nav-link">Home</a></li>
              <li><a href="#features-section" class="nav-link">Our Services</a></li>
              <li><a href="#pricing-section" class="nav-link">Features</a></li>
              <li><a href="#contact-section" class="nav-link">Contact</a></li>
              <li class="social"><a href="#contact-section" class="nav-link"><span class="icon-facebook"></span></a></li>
              <li class="social"><a href="#contact-section" class="nav-link"><span class="icon-flickr"></span></a></li>
              <li class="social"><a href="#contact-section" class="nav-link"><span class="icon-linkedin"></span></a></li>
              <li><a href="#" data-toggle="modal" data-target="#form" class="btn btn-primary sethover" style="background-color:#f60014;border:2px solid #f54f5d;padding: 7px;
                          margin-left: 17px;
                          margin-top: -7px;font-size: 12px;
                          font-weight: 600;">Offical Login</a> 
              </li>
              </ul>
         </nav>
        </div>
      <div class="col-6 d-inline-block d-xl-none ml-md-0 py-3" style="position: relative; top: 3px;"><a href="#" class="site-menu-toggle js-menu-toggle float-right"><span class="icon-menu h3 text-black"></span></a></div>
      </div>
    </div>
  </header>


<!--model start from here -->
  <div class="modal fade" id="form" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" style="padding-bottom: 35px;">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title" id="exampleModalLabel" style="color: chocolate;
          font-weight: 500;">Select Municipal ULB's</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form>
          <div class="modal-body">
            <select  onchange="(this.options[this.selectedIndex].value?  window.open(this.options[this.selectedIndex].value,'_blank'):'')" class="form-control">
              <option selected>Browse Your ULB's</option>
              <option value="http://kochasmunicipal.com/#/login">Kochas Nagar Panchayat</option>
              <option value="http://silaomunicipal.com/#/login">Silao Nagar Panchayat</option>
              <option value="http://aurangabadmunicipal.com/#/login">Aurangabad Nagar Parishad</option>
              <option value="http://bakhtiyarpur.biharmunicipal.com/#/login">Bakhtiyarpur Nagar Parishad</option> 
            </select>
          </div>
         
        </form>
      </div>
    </div>
  </div>